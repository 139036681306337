@import url(https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
body,
.MuiButton-root {
  margin: 0;
  font-family: "Caudex" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*::-webkit-scrollbar {
  width: 0.4em;
  /* height: 3px; */
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bf6159;
  outline: 1px solid #bf6159;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
h5 {
  font-size: 19px !important;
  font-weight: 400 !important;
}
.font-0 {
  font-size: 12px !important;
}
.font-1 {
  font-size: 16px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-18 {
  font-size: 1.5em !important;
}
.font-21 {
  font-size: 21px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-2 {
  font-size: 25px !important;
}

.font-3 {
  font-size: 31px !important;
}
.font-36 {
  font-size: 36px !important;
}

.font-4 {
  font-size: 48px !important;
}

.font-bold-1 {
  font-size: 16px !important;
  font-weight: bold !important;
}
.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.font-weight-bold {
  font-size: 16px;
}
.MuiBreadcrumbs-ol li a {
  font-size: 16px !important;
  color: #bf6159;
  text-decoration: none;
}
.slick-arrow.slick-next {
  right: 0px;
  background: white;
  height: 100%;
  width: 35px;
  display: flex;
  align-items: center;
  z-index: 2;
  border: 1px solid #bf6159;
  box-shadow: 0px 0px 6px #0000003b;
}
.slick-arrow.slick-next:hover {
  background: white;
}
.slick-arrow.slick-prev {
  left: 0px;
  background: white;
  height: 100%;
  width: 35px;
  display: flex;
  align-items: center;
  z-index: 2;
  border: 1px solid #bf6159;
  box-shadow: 0px 0px 6px #0000003b;
}

.slick-arrow.slick-prev:hover {
  background: white;
}
footer h6 {
  font-size: 25px;
}
.footer-links {
  padding-left: 0;
  list-style: none;
  min-height: 130px;
  border-right: 1px solid #707070;
  margin: auto 10px;
}
.footer-links li {
  padding-left: 0px;
  display: block;
}
.footer-links a {
  color: white;
}
.footer-links a:hover {
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.breadcrumb {
  background: transparent !important;
}
.breadcrumb .breadcrumb-item {
  font-size: 16px !important;
}
.breadcrumb a {
  color: #bf6159;
  text-decoration: none;
  font-size: 16px !important;
}

.breadcrumb a:hover {
  color: #bf6159;
  text-decoration: none;
}
.brands-header {
  margin-top: 75px;
}
.mx-15 {
  margin: 3px 9px;
}
.my-57 {
  margin-top: 57px;
  margin-bottom: 57px;
}
.mx-md-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}
.mt-lg-70 {
  margin-top: 1.5rem !important;
}
@media screen and (min-width: 1280px) {
  .mt-lg-70 {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 1280px) {
  .mx-md-140 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .ProductDetails,
  .ProductDetails .SliderSection {
    min-height: auto !important;
  }
}
.w-md-75 {
  width: 75% !important;
}
@media screen and (max-width: 670px) {
  .w-md-75 {
    width: 100% !important;
  }
  .row {
    margin: 0px !important;
  }
  .LeftSide {
    padding-left: 5px !important;
  }
  .mx-md-140 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
  .justify-md-content-center {
    justify-content: center !important;
  }
  .text-md-none {
    display: none !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .font-0 {
    font-size: 8px !important;
  }
  .font-1 {
    font-size: 12px !important;
  }
  .MuiDialog-paper {
    margin: 5px !important;
  }
  .MuiDialogContent-root {
    padding: 9px 0px !important;
  }
  .MuiGrid-spacing-xs-3 {
    width: 100% !important;
    margin: 0px !important;
  }
  .font-md-10 {
    font-size: 10px !important;
    text-transform: capitalize !important;
    margin-bottom: 5px;
  }
  .font-md-10 .MuiButton-label {
    font-size: 10px !important;
  }
  .font-18 {
    font-size: 13px !important;
  }
  .font-2 {
    font-size: 15px !important;
  }

  .font-3 {
    font-size: 20px !important;
  }
  .font-36 {
    font-size: 22px !important;
  }

  .font-4 {
    font-size: 24px !important;
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item,
  .px-md-0 {
    padding: 0px !important;
  }
  .MuiBox-root {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
.px-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}
@media screen and (max-width: 480px) {
  .px-130 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 670px) {
  .px-130 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .footer-links {
    border: none !important;
  }
  .product-items .MuiGrid-root {
    margin-bottom: 10px !important;
  }
  .MuiBottomNavigationAction-label {
    font-size: 10px !important;
  }
  .MuiBottomNavigationAction-root {
    min-width: 70px !important;
  }
  .ProductDetails {
    width: 99% !important;
    margin: auto !important;
  }
  .advapoints .MuiGrid-grid-xs-12 {
    max-width: 93% !important;
  }
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.text-main {
  color: #bf6159;
}
.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #707070;
  font-size: 31px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #bf6159 !important;
  background-color: transparent !important;
  border-top: 3px solid #bf6159 !important;
  font-weight: lighter;
}
.nav-tabs {
  border-bottom: 0px !important;
  border-top: 1px solid #d1d1d1;
}
.nav-tabs .nav-item {
  min-width: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductDetails,
.ProductDetails .SliderSection {
  min-height: 750px;
}

.ProductDetails .slick-dots {
  display: flex !important;
}
.ProductDetails .slick-dots li {
  width: 180px;
  height: 137px;
}
.ProductDetails .slick-slider,
.ProductDetails .slick-slider .slick-list {
  min-height: 577px;
}
.ProductDetails .slick-dots img {
  width: 100%;
  height: auto;
}
arrow-none .ProductDetails .slick-arrow.slick-next,
.ProductDetails .slick-arrow.slick-prev,
.arrow-none .slick-arrow.slick-next,
.arrow-none .slick-arrow.slick-prev {
  display: none !important;
}
.ProductDetails .slick-slide {
  display: flex !important;
  justify-content: center !important;
}
.ProductDetails .slick-dots {
  position: relative;
  bottom: auto;
}
.discount {
  text-decoration: line-through;
  -webkit-text-decoration-color: red;
          text-decoration-color: red;
  text-decoration-thickness: 2px;
}
.color-box {
  width: 25px;
  height: 25px;
  display: block;
  margin: auto 5px;
}
/* Stars */

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}

/* Stars */

.cross {
  font-size: 35px;
  margin: auto 5px;
  cursor: pointer;
}
hr {
  color: #707070;
  background-color: #bb534a;
}
.border-bottom-main {
  border-bottom: 1px solid #bb534a !important;
}

.modal-full-height {
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  top: 0;
  max-width: 100% !important;
  display: flex;
  height: auto;
  min-height: 100%;
  margin: 0;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: none;
          transform: none;
  right: 0;
  padding-right: 0% !important;
  margin: 0px !important;
  margin-right: 0px !important;
  margin-left: auto !important;
  width: 585px;
  transition: width 0.5s !important;
}
.d-flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.d-flex-between-center p {
  margin-bottom: 2px;
}
.d-flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.d-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.d-flex-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
div p.font-weight-bold {
  margin-bottom: 2px;
}
#SuccessModal .modal-content {
  background-color: black;
  color: white;
}
#SuccessModal p,
#SuccessModal h5 {
  color: white;
}
#ErrorModal .modal-content {
  background-color: #e65e52;
  color: white;
}
#ErrorModal p,
#ErrorModal h5 {
  color: white;
}
.w-65 {
  width: 65% !important;
}
.link-main {
  text-decoration: none !important;
  color: #bf6159 !important;
}
.mt-md-250 {
  margin-top: 250px;
}
@media only screen and (max-width: 1280px) {
  .brands-header p {
    margin: auto !important;
    text-align: center;
    font-size: 25px;
  }
  .mt-md-250 {
    margin-top: auto;
  }
}
.MuiFormControlLabel-label {
  font-size: 14px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #bf6159 !important;
}
.Mui-disabled.Mui-disabled {
  color: black;
}
.error {
  color: red;
  font-size: 14px;
}
.LeftSide {
  padding-left: 130px;
  background: white !important;
  border-right: 1px solid #c1c1c1 !important;
  border-left: 1px solid #c1c1c1 !important;
}
.offers-head {
  padding-top: 20px;
  font-weight: 500;
}
.offers {
  margin-bottom: 63px;
}
.top-sellers {
  margin-bottom: 98px;
}
.top-seller-head {
  padding-top: 20px;
  padding-bottom: 30px;
}
.product-head {
  margin-bottom: 40px;
}
.product-items .MuiGrid-root {
  margin-bottom: 56px;
}
.products {
  margin-bottom: 200px;
}
.brands-hero {
  margin-left: 36%;
  margin-right: 36%;
}
.product-hero {
  margin-left: 34%;
  margin-right: 34%;
}
footer {
  margin-top: 200px !important;
}
.MuiOutlinedInput-root {
  border-radius: 0% !important;
}
.offers .card {
  box-shadow: 0px 0px 6px #0000003b;
}

.mx-90 {
  max-width: 90%;
}

@media only screen and (max-width: 1280px) {
  .mx-90 {
    max-width: 100%;
  }
}
.question-icon::before {
  content: "\e757";
}
.question-icon {
  position: relative;
  color: #bf6159;
  font-size: 36px;
}
.answer-icon::before {
  content: "\e753";
  color: #bf6159;
  font-size: 36px;
}
#messages p {
  word-break: keep-all;
}
#messages .rcw-response .rcw-message-text,
.rcw-launcher {
  background-color: #bf6159 !important;
  z-index: 999999 !important;
}

.rcw-header {
  background-color: white !important;
  color: #bf6159 !important;
  border-bottom: 1px solid #bf6159;
}
.font-36-button svg {
  font-size: 36px !important;
}
.text-main {
  color: #bf6159 !important;
}
.imageUpload input {
  display: none;
}
.imageUpload {
  color: #bf6159;
  border-bottom: 1px solid #bf6159;
  cursor: pointer;
}
.MuiDivider-root {
  margin: 10px !important;
}
.auto {
  width: 100%;
  height: auto;
}
.MuiIconButton-root:hover {
  background-color: transparent;
}
.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: none !important;
}
.MuiInput-underline::before {
  border: none !important;
}
.MuiInputAdornment-root {
  cursor: pointer;
}

.MuiButton-containedPrimary {
  background: #bf6159 !important;
}
.MuiButton-outlinedPrimary {
  border-color: #bf6159 !important;
  color: #bf6159 !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #bf6159 !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: #bf6159 !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #bf6159 !important ;
}
input[type="checkbox"] + svg path {
  color: #bf6159 !important;
}
.MuiOutlinedInput-root,
.MuiButton-containedPrimary,
.MuiButton-outlinedPrimary,
.MuiPaper-rounded {
  border-radius: 0px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #bf6159 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #bf6159 !important;
}
.rcw-widget-container {
  bottom: 50px;
}
/* .top-sellers .slick-track{
  width: auto !important;
} */
video {
  width: 100% !important;
}
input[type="number"] {
  -webkit-appearance: text;
          appearance: text;
}
.swiper-container {
  max-width: 900px;
}

.swiper-pagination {
  bottom: 0;
  padding-bottom: 10px;
}

.swiper-wrapper {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}
.payment [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.payment [type="radio"] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
.payment [type="radio"]:checked + img {
  outline: 2px solid #f00;
}
[dir="rtl"] .rcw-widget-container {
  right: auto !important;
  left: 50px !important;
}
[dir="rtl"] .slick-prev {
  right: auto !important;
  left: 0px !important;
}
[dir="rtl"] .slick-next {
  right: 0px !important;
  left: auto !important;
}
body[dir="rtl"] {
  text-align: right !important;
}
body[dir="rtl"] .MuiListItem-root {
  text-align: unset !important;
}
body[dir="rtl"] .input {
  text-align: right !important;
}
body[dir="rtl"] label {
  text-align: right !important;
}
body[dir="rtl"] svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-left: 5px;
  margin-right: 5px;
}
body[dir="rtl"] button:not(.react-share__ShareButton) svg {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.mobilesearch .MuiOutlinedInput-input,
.search .MuiOutlinedInput-input {
  padding: 0px !important;
}
.filter a {
  color: #707070 !important;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.fTdgts {
  font-size: 16px !important;
}
.MuiBadge-badge {
  color: white !important;
  background-color: #bf6159 !important;
}

.new {
  position: absolute;
  left: 10px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  top: 10px;
  border: 2px solid black;
  background-color: black;
  min-width: 46px;
  text-align: center;
  padding: 3px;
}
.outofstock {
  position: absolute;
  left: 10px;
  color: white;
  font-size: 13px;
  border: 2px solid #bb534a;
  font-weight: bold;
  top: 10px;

  background-color: #bb534a;
  min-width: 46px;
  text-align: center;
  padding: 3px;
}

.hero-slider .slick-dots {
  bottom: 17px !important;
}
.hero-slider .slick-dots button::before {
  content: "-" !important;
  font-size: 60px !important;
  color: white !important;
}
.hero-slider .slick-dots li.slick-active button::before {
  color: #bf6159 !important;
}
#main-carousel {
  direction: ltr !important;
}
.loginModal .MuiDialog-paper {
  background-color: #000000b0;
  color: white;
  min-width: 500px;
  height: 300px;
}
.MuiButton-root {
  text-transform: capitalize !important;
}
svg {
  direction: ltr;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loader {
  left: 45%;
  top: 35%;
  z-index: 1000;
  position: absolute;
}

#NotificationMenu .MuiMenu-paper,
#WislistTopMenu .MuiMenu-paper {
  top: 60px !important;
}
.MuiDivider-root {
  background-color: #bb534a !important;
}
.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline:hover {
  border-color: #bb534a !important;
}
.search svg {
  color: #bb534a !important;
}
.MuiDialog-paper {
  min-width: 600px !important;
}
#ShareMenu .MuiMenu-paper {
  top: unset;
}
.border-main {
  border: 1px solid #bb534a !important;
}
.Payment-Next {
  width: 322px;
}
.f-48 {
  font-size: 48px !important;
}
.f-43 {
  font-size: 43px !important;
}
.f-40 {
  font-size: 40px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-25 {
  font-size: 25px !important;
}
.f-23 {
  font-size: 23px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-26 {
  font-size: 26px !important;
}
.f-31 {
  font-size: 31px !important;
}
.f-12 {
  font-size: 12px !important;
}
.fw-100 {
  font-weight: 100 !important;
}
.f-c {
  font-family: caudex !important;
}
hr {
  border-color: #bf6159 !important;
}
.MuiButton-label {
  text-transform: none !important;
  text-transform: initial !important;
}
.cart-image {
  max-height: 125px;
  max-width: 120px;
}
.mobile-size {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 250px;
}
@media screen and (max-width: 670px) {
  .f-23 {
    font-size: 17px !important;
  }
  .f-20 {
    font-size: 16px !important;
  }
  .f-25 {
    font-size: 18px !important;
  }
  .f-15 {
    font-size: 12px !important;
  }
  .f-48 {
    font-size: 17px !important;
  }
  .f-43 {
    font-size: 22px !important;
  }
  .f-18 {
    font-size: 15px !important;
  }
  .f-26 {
    font-size: 20px !important;
  }
  .f-40 {
    font-size: 22px !important;
  }
  .f-31 {
    font-size: 18px !important;
  }
  .mobile-size {
    max-width: 181px !important;
  }
  .mobile-size button {
    padding: 10px;
  }
  .mobile-size img {
    width: 20px !important;
  }

  .cart-image {
    max-height: 100px;
    max-width: 100px;
  }
  .Payment-Next {
    width: 150px;
  }
  .loader {
    left: 35%;
  }
  .MuiDialog-paper {
    min-width: 370px !important;
    padding: 5px;
  }
}
.post-style {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #bf615926;
  border: 1px solid #bf6159;
}
.form-control:focus {
  box-shadow: 0px 0px 6px #bf6159 !important;
}
.react-tel-input {
  border: 1px solid #bf6159 !important;
  margin-top: 5px !important;
}
.discount-chip {
  border-color: #bf6159 !important;
  color: #bb534a !important;
  box-shadow: 0px 0px 6px #bf6159 !important;
  margin: 5px !important;
  max-width: 150px !important;
}
.discount-chip svg {
  color: #bb534a !important;
}

.slick-carousel-image {
  height: 75vh !important;
  width: 100% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

@media (max-width: 1024px) {
  .slick-carousel-image {
    height: 50vh !important;
  }
}

